<template>
    <div class="loan-record">
       <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">申请进度</div>
    <div v-if="approvalList.length > 0">
        <div class="card" v-for="item in approvalList" :key="item">
            <div class="nums">需求编号：{{item.requireId}}</div>
            <div class="company">{{item.enterName}}</div>
            <div class="details">
                <p>申请机构：{{item.bankName}}</p>
                <p>申请人：{{item.applicationUserDescr}}</p>
                <p>提交时间：{{item.applicationTime}}</p>
            </div>
            <div class="flex">
                <div to="/loanProgressContent" class="loan-btns plan" @click="progressContent(item.approvalId)">
                <img src="../assets/img/record_icon1.png" alt="">
                <p>进度详情</p>
                </div>                
                <div v-if="item.status==='-1'||item.status==='7'" class="loan-btns eval" @click="goEvalOrg(item.requireId,item.bankCode)">
                <img src="../assets/img/userxx.png" alt="">
                <p>评价</p>
                </div>
                <div v-else class="loan-btns break" style="display:none" @click="stopLoan(item.approvalId)">
                <img src="../assets/img/progress_icon.png" alt="">
                <p>终止流程</p>
                </div>
            </div>
            <img class="status" :src="require(`../assets/img/sqjd${item.status}.png`)" alt="">
        </div>
<!-- 
        <div class="card">
            <div class="nums">需求编号：10955</div>
            <div class="company">南宁金电图腾软件有限公司</div>
            <div class="details">
                <p>申请机构：中国工商银行股份有限公司南宁支行</p>
                <p>申请人：刘宇锋</p>
                <p>提交时间：2021-10-08 19:45:04</p>
            </div>
            <div class="flex">
                <router-link to="/loanProgressContent" class="loan-btns plan">
                <img src="../assets/img/record_icon1.png" alt="">
                <p>进度详情</p>
                </router-link>
                <router-link to="/evalOrg" class="loan-btns eval">
                <img src="../assets/img/userxx.png" alt="">
                <p>评价</p>
                </router-link>
            </div>
            <img class="status" src="../assets/img/dailu.png" alt="">
        </div> -->

    </div>
        <!-- 没有数据 -->
        <div v-else class="card2">
            <img src="@/assets/img/gxm2.png" alt="">
            <div>无相关记录</div>      
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { apiApprovalList } from '../api/axios'
export default {
    setup() {
        let router= useRouter();

        let pId = router.currentRoute.value.query.requireId;
        // console.log(Date.parse(new Date()),888);
        const approvalList = ref([]);
        apiApprovalList({requireId: pId}).then(res => {              
             if(res.code == '1'){                
                approvalList.value = res.info.approvalList;
              }
              else {
                Toast(res.msg);
              }
          });
        const progressContent=(pid) => {
            router.push({ path:'/loanProgressContent',
                query: {
                    approvalId: pid
                }
            });
  
        };
        const stopLoan=(sid) => {
            router.push({ name:'stopLoan',params:{approvalId: sid}})
  
        };
        const goEvalOrg=(id,code) => {
            router.push({ name:'evalOrg',params:{requireId: id,bankCode:code}})
  
        };
        const goBack=() => {
            // router.push({ name:'User'})
            router.go(-1);
        };
        return {
            approvalList,
            progressContent,
            stopLoan,
            goEvalOrg,
            goBack
        }
    },

}
</script>
<style lang="less" scoped>
.loan-record {
    padding-top: 12.5vw;    
    .card {
        position: relative;
        .nums {
            display: inline-block;
            color: #ff9900;
            border: 1px solid #ff9900;
            border-radius: 1vw;
            font-size: 4vw;
            padding: 0 2vw;
        }
        .company {
            color: #6699ff;
            font-size: 4.8vw;
            font-weight: 500;
            padding-top: 2vw;
        }
        .details {
            width: 100%;
            font-size: 3.5vw;
            color: #666666;
            padding: 2vw 0;
            p {
            margin: 1.5vw 0;
            }
            
        }
        .loan-btns {
            display: flex;
            color: #fff;
            font-size: 4vw;
            padding: 2vw 4vw;
            border-radius: 4vw;
            margin-right: 4vw;
            img {
                height: 4.5vw;
                margin-right: 2vw;
            }
            p {
                line-height: 4.5vw;
            }
        }
        .plan {
            background: linear-gradient(to right, #48e7cb, #52c8f2);
        }
        .break {  
            background: linear-gradient(to right, #ff6532, #ff0201);  
        }
        .eval {
            background: linear-gradient(to right, #0db0ff, #5571ff);
        }
        img.status {
            width: 19.5vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
    .card2 {
    text-align: center;
    img {
      width: 30vw;
      height: auto;
      margin-top: 18vw;
    }
    div {
      text-align: center;
      font-size: 4vw;
      margin: 10vw 0;
    }
  }
}
</style>